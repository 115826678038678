import React from "react";
import logo from "../assets/Image/lyxa-logo.png";
import "./style.css";

function InValidUrl({ loading }) {
  return (
    <div className="invalid-page-container">
      {!loading && <img className="logo" src={logo} alt="Logo" />}
      <div className="invalid-container-main">
        <div className="invalid-container-card">
          {loading ? (
            <div className="spinner-border text-danger my-4" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <svg
                width="150"
                height="150"
                viewBox="0 0 508 406"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M501.588 347.774L291.169 19.6796C283.515 7.70958 269.342 0.335205 254 0.335205C238.657 0.335205 224.485 7.71552 216.831 19.6796L6.41203 347.774C-1.25328 359.72 -1.24734 374.439 6.41797 386.386C14.0892 398.332 28.2561 405.688 43.5808 405.676H464.419C479.75 405.682 493.916 398.332 501.582 386.386C509.247 374.439 509.253 359.72 501.588 347.774ZM279.335 355H228.67V304.335H279.335V355ZM279.335 101.665V278.994H228.67V101.659L279.335 101.665Z"
                  fill="#D9D9D9"
                />
              </svg>
              <div className="text-container">
                <p>You have already changed your password!</p>
                <p style={{ fontSize: "24px", fontWeight: "400" }}>
                  Please check the url and try again
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InValidUrl;
