import axios from "axios";
import React, { useEffect, useState } from "react";

import logo from "../assets/Image/lyxa-logo.png";
import InValidUrl from "./InValidUrl";
import { CHECK_VALIDITY_URL, RESET_PASSWORD_URL } from "../network/Api";

const ForgetPage = () => {
  let urlParams = window.location.search;
  let params = new URLSearchParams(urlParams);
  let email = params.get("email");
  let type = params.get("type");
  let token = params.get("token");

  const [pass, setPass] = useState("");
  const [rePass, setRePass] = useState("");
  const [error, setError] = useState("");
  const [success, setSucess] = useState("");
  const [loader, setLoader] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handlePassword = (e) => {
    setPass(e.target.value);
  };
  const handleReTypePassword = (e) => {
    setRePass(e.target.value);
  };

  const validateUrl = () => {
    const data = {
      token: token,
      email,
      type,
    };

    setLoader(true);
    axios
      .post(CHECK_VALIDITY_URL, data)
      .then((res) => {
        if (res?.data?.status) {
          setLoader(false);
          // console.log({ data: res?.data?.data?.isValid });
          setIsValid(() => {
            return res?.data?.data?.isValid;
          });
        }
      })
      .catch((error) => {
        setIsValid(false);
        setLoader(false);
        // console.log(error);
      });

    // console.log({ data });
  };

  const submitPassword = () => {
    setLoader(true);
    // console.log(pass, rePass);
    if (pass === "" || rePass === "") {
      setLoader(false);
      setError("Please type password");
      setPass("");
      setRePass("");
    } else if (pass !== rePass) {
      setLoader(false);
      setError("Password not matched");
      setPass("");
      setRePass("");
    } else {
      const data = {
        token: token,
        password: pass,
        email,
        type,
      };
      // console.log("data", data);
      setSucess("");
      setError("");
      axios.post(RESET_PASSWORD_URL, data).then((res) => {
        setLoader(false);
        // console.log(res);
        setPass("");
        setRePass("");
        if (res.data.status === false) {
          setSucess("");
          setSucess("");
          setError(res.data.message);
          setIsValid(false);
          validateUrl();
        } else if (res.data.status === true) {
          setError("");
          setSucess(res.data.message);
        }
      });
    }
  };

  useEffect(() => {
    validateUrl();
  }, []);

  return (
    <div className="parent-div">
      {isValid ? (
        <div className="form-parent">
          <div className="form">
            <img className="logo" src={logo} alt="Logo" />

            <h4 className="text-black title-style">Forget password</h4>

            {error && (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                  viewBox="0 0 16 16"
                  role="img"
                  aria-label="Warning:"
                >
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                <strong>{error}</strong>
              </div>
            )}
            {success && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                  viewBox="0 0 16 16"
                  role="img"
                  aria-label="Warning:"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                <strong>{success}</strong>
              </div>
            )}

            {isValid && (
              <div className="form-floating mb-3 mt-4">
                <input
                  required
                  value={pass}
                  onChange={handlePassword}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                />
                <label htmFor="floatingInput">Password</label>
              </div>
            )}
            {isValid && (
              <div className="form-floating">
                <input
                  required
                  value={rePass}
                  onChange={handleReTypePassword}
                  type="password"
                  className="form-control"
                  placeholder="Re-type Password"
                />
                <label htmFor="floatingInput">Re-type Password</label>
              </div>
            )}
            {loader ? (
              <div className="spinner-border text-danger my-4" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button
                className="submitBtn text-black  my-4 text-center"
                onClick={submitPassword}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      ) : (
        <InValidUrl loading={loader} />
      )}
    </div>
  );
};

export default ForgetPage;
